// Here you can add other styles

// ----------- HEADER --------------//
.header {
    background-color: #E6ECEF;
    border-bottom: #E6ECEF;
}
// ----------- NAVBAR --------------//
.sidebar-nav .nav-link {
    color: #083F5D;
    padding-left: 35px;
    font-size: 14px;
    font-weight: 700;
}

.sidebar-nav .nav-link:focus {
    color: #37B9FF;
    background-color: #E6ECEF;
}

.sidebar-nav .nav-link:hover {
    color: #37B9FF;
    background-color: #E6ECEF;
}

.sidebar-nav .nav-link.active {
    color: #37B9FF;
    background-color: #E6ECEF;
    border-left: 6px solid;
}

.sidebar-nav .nav-link .nav-icon {
    color: rgba(8, 63, 93, 0.4);
}

.sidebar-nav .nav-link:focus .nav-icon {
    color: #37B9FF;
}

.sidebar-nav .nav-link:hover .nav-icon {
    color: #37B9FF;
}

.sidebar-nav .nav-link.active .nav-icon {
    color: #37B9FF;
}

.sidebar-nav .nav-group-items .nav-link {
    padding-left: 2.6rem;
}

.sidebar-nav .nav-group.show {
    background-color: rgba(55, 185, 255, 0.1);
}

.sidebar-nav .nav-group.show .nav-group-toggle {
    color: rgba(8, 63, 93, 0.4);
    background-color: #FFFFFF;
}
